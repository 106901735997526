<template>
  <div class="character_details">
    <Top name="商品详情" email back></Top>
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      style="height: calc(100% - 40px); overflow-y: auto"
    >
      <van-loading v-if="isLoading" color="#1989fa" />
      <div v-else class="chara_w">
        <div class="index_list">
          <img class="profilePhoto" :src="detailData.imgSrc" />
          <span class="index_list_c">
            <div class="index_list_c_t" v-if="goods_type == 1">
              {{ detailData.channel_name }}\{{ detailData.job }}\{{
                detailData.lv
              }}级
            </div>
            <div class="index_list_c_t" v-else-if="goods_type == 3">
              {{ detailData.channel_name }}\{{ detailData.nickname }}
            </div>
            <div class="index_list_c_t" v-else>
              {{ detailData.channel_name }}\{{ detailData.nickname }}&ensp;{{
                detailData.currencyNum / 10000
              }}万
            </div>
            <div class="index_list_c_c">{{ detailData.server_name }}</div>
            <div class="index_list_c_b">￥{{ detailData.price }}</div>
          </span>
          <span class="index_list_r">
            <img
              class="authentication_img"
              src="../../assets/images/authentication.png"
            />
          </span>
        </div>
        <div class="hint">
          <div>
            <svg
              t="1623126301829"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2514"
              width="32"
              height="32"
            >
              <path
                d="M544 70.4l320 153.6c19.2 12.8 32 32 32 57.6v185.6c0 57.6-12.8 108.8-32 166.4-19.2 51.2-44.8 102.4-76.8 147.2-32 44.8-76.8 89.6-121.6 121.6s-96 51.2-153.6 64c-57.6-12.8-108.8-32-153.6-64s-89.6-76.8-121.6-121.6-57.6-89.6-76.8-147.2C140.8 576 128 518.4 128 460.8V281.6c0-25.6 12.8-44.8 32-57.6l320-153.6c19.2-6.4 44.8-6.4 64 0zM512 140.8L192 288v172.8c0 51.2 6.4 96 25.6 147.2 19.2 44.8 38.4 89.6 70.4 128 38.4 51.2 70.4 83.2 108.8 108.8 32 19.2 70.4 38.4 115.2 51.2 44.8-12.8 83.2-32 115.2-51.2 32-25.6 70.4-57.6 108.8-108.8 32-38.4 51.2-76.8 70.4-128 19.2-51.2 25.6-96 25.6-147.2V288L512 140.8z m179.2 198.4l44.8 44.8-268.8 262.4-179.2-179.2 44.8-44.8 134.4 134.4 224-217.6z"
                p-id="2515"
                fill="#fff"
              ></path>
            </svg>
            <span>安全交易 官方认证</span>
          </div>
        </div>
        <ul class="the_order_details">
          <li v-if="params.channel_id == 18?(goods_type == 1):true">
            <label>商品编号：</label><span>{{ detailData.goods_id }}</span>
          </li>
          <li v-if="goods_type == 1">
            <label>角色昵称：</label><span>{{ detailData.nickname }}</span>
          </li>
          <li v-if="goods_type == 2">
            <label>货币ID：</label><span>{{ detailData.item_id }}</span>
          </li>
          <li v-if="goods_type == 2">
            <label>货币数量：</label><span>{{ detailData.currencyNum }}</span>
          </li>
          <li v-if="goods_type == 3">
            <label>装备ID：</label><span>{{ detailData.item_id }}</span>
          </li>
          <li>
            <label>状态：</label
            ><span class="orange">{{ detailData.status_name }}</span>
          </li>
          <li>
            <label>剩余：</label><span>{{ detailData.surplus_time }}</span>
          </li>
          <li v-if="goods_type == 1">
            <label>角色ID：</label><span>{{ detailData.seedname }}</span>
          </li>
        </ul>
        <div style="padding-bottom: 3.7rem; background: #ececec">
          <div v-if="goods_type == 1">
            <van-cell
              v-for="item in cellList"
              :key="item.id"
              :title="item.name"
              is-link
              @click="detailLink(item)"
            />
          </div>
          <ItemDetailCon
            :detailObj1="detailObj"
            :loading="loading1"
            v-else-if="goods_type == 3"
          ></ItemDetailCon>
        </div>
        <div class="price_tag">
          <span class="price_tag_word">一口价：</span>
          <span class="orange">￥{{ detailData.price }}</span>
          <van-button
            :disabled="
              detailData.is_self == 3 ||
              params.is_from == 'onSale' ||
              detailData.status != 2 ||
              loading
            "
            class="purchase_btn"
            round
            color="linear-gradient(to right, #FF704D, #F73727)"
            @click="payClick"
            size="large"
            >{{ btnText }}</van-button
          >
        </div>
      </div>
    </van-pull-refresh>

    <van-dialog
      v-model="equipShow"
      title=""
      show-cancel-button
      @confirm="equipConfirm"
    >
      <div class="equip_w" v-if="goods_type == 3">
        <van-form>
          <van-field
            label="收货区服"
            value=""
            v-model="equipForm.server_name"
            readonly
          />
          <van-field
            readonly
            clickable
            name="picker"
            :value="equipForm.role"
            label="收货角色"
            placeholder="请选择"
            @click="equipForm.rolePicker = true"
          />
          <van-popup
            v-model="equipForm.rolePicker"
            position="bottom"
            duration="0.15"
            get-container="body"
          >
            <van-picker
              show-toolbar
              value-key="chrname"
              :columns="equipForm.roles"
              @confirm="onConfirm"
              @cancel="equipForm.rolePicker = false"
            />
          </van-popup>
        </van-form>
        <p class="orange">* 需在商品所在区服拥有角色，否则无法进行购买。</p>
      </div>
      <div class="equip_w" v-else-if="goods_type == 2">
        <van-form>
          <van-field
            readonly
            clickable
            name="picker"
            :value="currencyForm.region"
            label="收货大区"
            placeholder="请选择"
            @click="changeClick(3)"
          />
          <van-popup
            v-model="currencyForm.gamePicker"
            position="bottom"
            duration="0.15"
            get-container="body"
          >
            <van-picker
              show-toolbar
              value-key="regione_name"
              :columns="currencyForm.regions"
              @confirm="onConfirm"
              @cancel="currencyForm.gamePicker = false"
            />
          </van-popup>
          <van-field
            readonly
            clickable
            name="picker"
            :value="currencyForm.server"
            label="收货区服"
            placeholder="请选择"
            @click="changeClick(1)"
          />
          <van-popup
            v-model="currencyForm.serverPicker"
            position="bottom"
            duration="0.15"
            get-container="body"
          >
            <van-picker
              show-toolbar
              value-key="server_name"
              :columns="currencyForm.servers"
              @confirm="onConfirm"
              @cancel="currencyForm.serverPicker = false"
            />
          </van-popup>
          <van-field
            readonly
            clickable
            name="picker"
            :value="currencyForm.role"
            label="收货角色"
            placeholder="请选择"
            @click="changeClick(2)"
          />
          <van-popup
            v-model="currencyForm.rolePicker"
            position="bottom"
            duration="0.15"
            get-container="body"
          >
            <van-picker
              show-toolbar
              value-key="chrname"
              :columns="currencyForm.roles"
              @confirm="onConfirm"
              @cancel="currencyForm.rolePicker = false"
            />
          </van-popup>
        </van-form>
      </div>
    </van-dialog>
    <van-dialog
      class="choosePay"
      v-model="payshow"
      title=""
      :show-confirm-button="false"
    >
      <div class="close"><i @click="payshow = false"></i></div>
      <div class="title">选择支付方式</div>
      <ul class="choosePay_list">
        <li @click="choosePya('1')">
          <img src="../../assets/images/alipay_pay.png" />
          <div class="blue">支付宝支付</div>
        </li>
        <li @click="choosePya('2')">
          <img src="../../assets/images/yue.png" />
          <div>
            <div class="orange">余额支付</div>
            <div class="yue">
              <span>账户可用余额为：</span>
              <span class="orange">{{ accountBalance }}元</span>
            </div>
          </div>
        </li>
      </ul>
    </van-dialog>
    <van-dialog
      class="choosePay password_input"
      v-model="input_show"
      title=""
      :show-confirm-button="false"
    >
      <div class="close"><i @click="input_show = false"></i></div>
      <div class="title">请输入支付密码</div>
      <van-password-input
        :value="password"
        :focused="showKeyboard"
        @focus="showKeyboard = true"
      />
      <!-- <div class="password_forget">
                <router-link :to="{ name: 'Passwordset', params: { active: 1}}"><span class="blue">忘记密码</span></router-link>
            </div> -->
      <div class="affirm_pay">
        <van-button
          :disabled="password.length !== 6"
          round
          color="linear-gradient(to right, rgb(255, 112, 77), rgb(247, 55, 39))"
          size="large"
          @click="confirm_payment"
          >确认支付</van-button
        >
      </div>
    </van-dialog>
    <van-number-keyboard
      v-model="password"
      :show="showKeyboard"
      :maxlength="6"
      @blur="showKeyboard = false"
    />
  </div>
</template>
<script>
import Top from "@/components/top";
import ItemDetailCon from "@/components/itemDetailCon";
import { debounce } from "@/assets/js/utils.js";

export default {
  name: "characterDetails",
  data() {
    return {
      paymoney: "",
      input_show: false,
      showKeyboard: false,
      password: "",
      payshow: false,
      value: "",
      isLoading: true,
      params: {},
      detailData: {
        channel_name: "",
        job: "",
        lv: "",
        server_name: "",
        server_id: "",
        price: "",
        imgSrc: "",
        goods_id: "",
        nickname: "",
        seedname: "",
        status_name: "",
        surplus_time: "",
        currencyNum: 0,
        item_id: "",
      },
      cellList: [],
      detailObj: {
        // goods_name: '454',
        // hp: '878',
        // mp: "879",
        // need_lv: "9",
        // goods_lv: 6,
        // need_zslevel: "5",
        // goods_desp: "今天天气可真好啊，适合晒太阳",
        // additional_attribute: [
        //     {
        //         title: '12',
        //         title_value: '12-34'
        //     },{
        //         title: '魔法',
        //         title_value: '12-34'
        //     }
        // ],
        // basics_attribute: [
        //     {
        //         title: '12',
        //         title_value: '12-34'
        //     },{
        //         title: '魔法',
        //         title_value: '12-34'
        //     }
        // ],
        // element_attribute: [
        //     {
        //         title: '12',
        //         title_value: '12-34'
        //     },{
        //         title: '魔法',
        //         title_value: '12-34'
        //     }
        // ],
        // suit_attribute: [
        //     {
        //         all_goods: "望月仙の手<br>皓月仙の链<br>",
        //         desp: "攻魔道+140;吸血+4%;怪物伤害减免+16%",
        //         title: "4件望月皓月可激发属性"
        //     }
        // ]
      },
      loading: true,
      isSet: false,
      refreshing: false,
      goods_type: 1,
      loading1: false,
      equipShow: false,
      equipForm: {
        server_name: "",
        server_id: "",
        role: "",
        seedname: "",
        rolePicker: false,
        roles: [],
      },
      currencyForm: {
        channelId: "",
        region: "",
        regione_id: "",
        regions: [],
        gamePicker: false,
        server: "",
        server_id: "",
        serverPicker: false,
        servers: [],
        role: "",
        seedname: "",
        rolePicker: false,
        roles: [],
      },
      state: 1,
      appData: {},
      accountBalance: "",
      frozenBalance: "",
      debounce,
    };
  },
  components: {
    Top,
    ItemDetailCon,
  },
  //  beforeRouteLeave(to, from, next) {
  //   console.log('beforeRouteLeave-to',to);
  //   console.log('beforeRouteLeave-from',from);
  //   next();
  // },
  created() {

    this.params = this.$route.query;
    console.log(this.params)
    if (localStorage.getItem("a")) {
      this.appData = JSON.parse(localStorage.getItem("a"));
    }
    // this.getMoney()

    //console.log(this.params)
    //获取详情数据
    this.getDetailData();
    //判断是否已安全设置
    //this.isSettig();
    //获取渠道列表
    //this.gameChannelList();
    //清除支付宝支付留下的元素
    let divnewForm = document.getElementsByTagName("divnewform");
    // console.log(divnewForm)
    if (divnewForm.length) {
      document.body.removeChild(divnewForm[0]);
    }
    //获取路由参数
    // this.params = this.$route.query;
    // this.paymoney = this.params.number;
    this.ip = localStorage.getItem("ip");
    // console.log(this.ip)
    let isPay = localStorage.getItem("isPay");
    // console.log(window.history.length)
    // if (isPay == "true" || window.history.length <= 2) {
    //   // console.log(223332)
    //   this.checkOrder();
    // }

    // console.log(JSON.parse(localStorage.getItem('a')))
    this.getBalance();

    //防抖动
    this.click = this.debounce(this.payRequest, 500);
  },
  computed: {
    btnText: function () {
      if (this.detailData.status == 8) {
        // console.log('公示期')
        return this.detailData.surplus_time;
      } else if (this.params.is_from == "sold") {
        return "已售出";
      } else {
        return "立即购买";
      }
    },
  },
  methods: {
    // 确认支付按钮
    confirm_payment() {
      // console.log(this.paymoney)
      this.$toast({
        duration: 1500,
        message: "支付中······",
      });
      this.$api.shopping.checkPayPassword({
          payPw: this.password,
        }).then((res) => {
          // console.log(res);
          if (res.code == 200) {
            // console.log(this.paymoney)
            //
            if (this.paymoney == 0) {
              //  余额支付
              this.paySubmit();
            } else {
              this.$toast({
                duration: 1500,
                message: "余额不足",
              });
              return false;
              // 混合支付
              let data = {
                  goods_id: this.detailData.goods_id,
                  number: this.paymoney,
                  checked: 1,
                },
                dataObj = {};
              // console.log(data)
              if (this.appData.server_id) {
                dataObj = data;
                dataObj.to_server = this.appData.server_id;
                dataObj.chrname = this.currencyForm.role;
              } else {
                dataObj = data;
              }
              // console.log(dataObj)
              this.$router.push({
                name: "paymentChannels",
                query: dataObj,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //余额支付
    paySubmit() {
      // let isbalance = this.checked ? 1 : 0,
      let data = {
          username: localStorage.getItem("username"),
          goods_id: this.detailData.goods_id,
          pay_type: "ali",
          type: "wap",
          is_balance: 1,
        },
        dataObj = {};
      if (this.currencyForm.server_id) {
        dataObj = data;
        dataObj.to_server = this.currencyForm.server_id;
        dataObj.chrname = this.currencyForm.role;
      } else {
        dataObj = data;
      }
      // console.log(data)
      this.$api.shopping.createOrderAli(dataObj).then((res) => {
          if (res.code == 200) {
            //this.$toast.success('支付成功');
            this.input_show = false;
            // this.$router.push({
            //     name: 'Paysuccess',
            // });
            this.$toast({
              duration: 1500,
              message: "购买成功",
            });
            this.$router.push({
              path: "/",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //查看支付是否成功
    checkOrder() {
      //每2s查询一次，直到获取到结果为止
      let timer;
      // console.log(123)
      timer = setInterval(() => {
        this.$api.shopping.checkOrder({
            goods_id: this.params.goods_id,
          }).then((res) => {
            //支付宝支付拿到结果后结束轮询
            clearInterval(timer);
            //针对微信支付拿到结果后清除存的值
            localStorage.removeItem("isPay");
            if (res.code == 200) {
              this.$toast({
                duration: 1500,
                message: "购买成功",
              });
              this.$router.push({
                path: "/",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 2000);
    },
    //计算需要支付的金额
    getMoney() {
      //开启抵扣
      this.$api.shopping.ableAllPay({
          goods_id: this.params.goods_id,
        }).then((res) => {
          if (res.code == 200) {
            // console.log(res)
            //alert(res.data.able_all_balance_pay);
            if (res.data.able_all_balance_pay == 1) {
              this.paymoney = 0;
            } else {
              // this.paymoney = this.detailData.price;
              this.paymoney = this.accSub(
                this.detailData.price,
                this.accountBalance
              );
              // this.checked = false;
            }
          }
        }).catch((err) => {
          console.log(err);
        });
      this.input_show = true;
      this.payshow = false;
    },
    //精确计算小数减法结果
    accSub(arg1, arg2) {
      if (isNaN(arg1)) {
        arg1 = 0;
      }
      if (isNaN(arg2)) {
        arg2 = 0;
      }
      arg1 = Number(arg1);
      arg2 = Number(arg2);

      var r1, r2, m, n;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
      n = r1 >= r2 ? r1 : r2;
      return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },
    //获取账户余额
    getBalance() {
      let username = localStorage.getItem("username");
      this.$api.account.getBalance({ username: username }).then((res) => {
          if (res.code == 200) {
            this.accountBalance = res.data.accountBalance;
            this.frozenBalance = res.data.frozenBalance;
          }
        }).catch((err) => {
          console.log(err);
        });
    },
    // 选择付款方式
    choosePya(type) {
      if (type == 1) {
        // 支付宝支付
        this.click();
      } else {
        // 余额支付
        this.$api.account.getBalance({ username: localStorage.getItem("username") }).then((res) => {
            if (res.code == 200) {
              if (res.data.is_set_up == 0) {
                this.isSet = false;
                this.$toast({
                  duration: 1500,
                  message: "为保证您的账户安全，请先完成安全设置哦！",
                });
                this.$router.push("/safesetting");
              } else {
                this.isSet = true;
                this.getMoney();
                //计算抵扣后需要支付的金额

                // this.showKeyboard = true
              }
            }
          });
      }
    },
    //提交支付请求
    payRequest() {
      // //微信支付成功后会新窗口打开页面，所以用本地存值的方法来判断是否发起订单结果请求
      localStorage.setItem("isPay", true);
      // let isbalance;
      // isbalance =  0;
      //支付宝支持成功后会返回到原界面，所以支付后原界面需要通过轮询的方法来判断是否发起订单结果请求
      let param, contents, tok;
      tok = localStorage.getItem("token").substring(7);
      console.log(this.currencyForm.server_id)
      if (this.currencyForm.server_id) {
        // console.log(111)
        param = {
          username: localStorage.getItem("username"),
          goods_id: this.detailData.goods_id,
          pay_type: "alipay",
          type: "wap",
          is_balance: 0,
          token: tok,
          to_server: this.currencyForm.server_id,
          chrname: this.currencyForm.role,
        };
      } else {
        // console.log(222)
        param = {
          username: localStorage.getItem("username"),
          goods_id: this.detailData.goods_id,
          pay_type: "alipay",
          type: "wap",
          is_balance: 0,
          token: tok,
        };
      }
      // console.log(param,this.detailData)
      // return
      // console.log(param)
      // return
      contents = this.$Base64.encode(JSON.stringify(param));
      this.$api.shopping.createOrderAli({
          content: contents,
        }).then((res) => {
          console.log(res); //res.code=400弹出不能购买自己订单
          // return false
          // let res1=JSON.parse(res)
          // console.log(res1);
          //存放原始数据
          if (res.code == 400) {
            //失败什么都不做，仅弹出提示，否则都为成功
          } else {
            let divForm = document.getElementsByTagName("divform");
            if (divForm.length) {
              document.body.removeChild(divForm[0]);
            }
            //存放提取出来的form表单
            let divnewForm = document.getElementsByTagName("divnewform");
            if (divnewForm.length) {
              document.body.removeChild(divnewForm[0]);
            }
            const div = document.createElement("divform");
            div.innerHTML = res; // data就是接口返回的form 表单字符串
            document.body.appendChild(div);
            //将提取出来的form元素放到新元素里
            let divs = document.createElement("divnewform");
            divs.innerHTML = "";
            divs.appendChild(document.forms[0]);
            document.body.appendChild(divs);
            // document.body.removeChild(divForm[0]);
            //console.log(document.forms[0]);
            //document.forms[0].setAttribute('target', '_blank'); // 新开窗口跳转
            document.forms[0].submit();
          }
        }).catch((err) => {
          console.log(err);
        });
    },
    //下拉刷新
    onRefresh() {
      //获取详情数据
      this.getDetailData();
    },
    //获取商品详情数据
    getDetailData() {
      this.$api.shopping.goodsDetails({
          goods_id: this.params.goods_id,
        }).then((res) => {
          this.refreshing = false;
          this.isLoading = false;
          if (res.code == 200) {
            this.goods_type = res.data.goods_type;
            this.detailData = res.data.goods_detal;
            //剩余时间处理
            this.detailData.surplus_time =
              this.detailData.surplus_time.indexOf("剩余") > -1
                ? this.detailData.surplus_time.substring(3)
                : this.detailData.surplus_time;
            //获取头像
            if (res.data.goods_type != 1) {
              //货币、装备
              try {
                this.detailData.imgSrc = require("../../assets/images/itemicon/" +
                  this.params.channel_id +
                  "/" +
                  res.data.goods_detal.icon_id +
                  ".png");
              } catch (e) {
                this.detailData.imgSrc = require("../../assets/images/question.png");
              }
            } else {
              //获取头像 角色
              if (this.detailData.sex == 200) {
                switch (this.detailData.job) {
                  case "战士":
                    this.detailData.imgSrc = require("../../assets/images/photos/zs-nan@2x.png");
                    break;
                  case "法师":
                    this.detailData.imgSrc = require("../../assets/images/photos/fa-nan@2x.png");
                    break;
                  default:
                    this.detailData.imgSrc = require("../../assets/images/photos/ds-nan@2x.png");
                }
              } else {
                switch (this.detailData.job) {
                  case "战士":
                    this.detailData.imgSrc = require("../../assets/images/photos/zs-nv@2x.png");
                    break;
                  case "法师":
                    this.detailData.imgSrc = require("../../assets/images/photos/fa-nv@2x.png");
                    break;
                  default:
                    this.detailData.imgSrc = require("../../assets/images/photos/ds-nv@2x.png");
                }
              }
            }

            //根据类型判断角色、货币、装备
            switch (res.data.goods_type) {
              case 1:
                //获取角色详情列表名称
                this.cellList = res.data.goods_menu;
                break;
              case 2:
                //获取货币数量
                this.detailData.currencyNum = res.data.goods_menu;
                //获取大区列表
                if (this.appData.server_id) {
                  // console.log(3)
                  this.$api.shopping.regionList({
                      channel_id: this.params.channel_id,
                    }).then((resData) => {
                      this.isLoading = false;
                      if (resData.code == 200) {
                        this.currencyForm.regions = resData.data;
                        //首次点开默认值
                        if (this.state == 1) {
                          this.currencyForm.channelId = this.params.channel_id;
                          this.$api.helper.getServerList({
                              channel_id: this.params.channel_id,
                            }).then((res) => {
                              if (res.code == 200) {
                                for (var key in res.data) {
                                  this.currencyForm.servers.push({
                                    server_name: res.data[key].server_name,
                                    server_id: res.data[key].server_id,
                                  });
                                }
                                //首次点开默认值
                                if (this.state == 1) {
                                  let regione_id;
                                  res.data.forEach((element) => {
                                    // console.log(element)
                                    if (
                                      element.server_id ==
                                      this.appData.server_id
                                    ) {
                                      this.currencyForm.server =
                                        element.server_name;
                                      this.currencyForm.server_id =
                                        element.server_id;
                                      regione_id = element.regione_id;
                                    }
                                  });
                                  // console.log(regione_id)
                                  resData.data.forEach((element) => {
                                    // console.log(element)
                                    if (element.regione_id == regione_id) {
                                      this.currencyForm.region =
                                        element.regione_name;
                                      this.currencyForm.regione_id =
                                        element.regione_id;
                                      this.currencyForm.channelId =
                                        this.params.channel_id;
                                    }
                                    // if(element.regione_id==this.appData.regione_id){
                                    //     this.currencyForm.region = element.regione_name;
                                    //     this.currencyForm.regione_id = element.regione_id;
                                    //     this.currencyForm.channelId = this.params.channel_id;
                                    // }
                                  });
                                  this.$api.helper.getPlayerList({
                                      channel_id: this.currencyForm.channelId,
                                      server_id: this.currencyForm.server_id,
                                      type: 1,
                                    }).then((ress) => {
                                      if (ress.code == 200) {
                                        if (this.goods_type == 2) {
                                          // console.log(res)
                                          this.currencyForm.roles =
                                            ResizeObserverEntry.data;
                                          // if()
                                          //首次点开默认值
                                          if (this.state == 1) {
                                            ress.data.forEach((element) => {
                                              if (
                                                element.seedname ==
                                                this.appData.seedname
                                              ) {
                                                // console.log(element)
                                                this.currencyForm.role =
                                                  element.chrname;
                                                this.currencyForm.seedname =
                                                  element.seedname;
                                              }
                                            });
                                          }
                                        } else {
                                          // console.log(res)
                                          this.equipForm.roles = ress.data;

                                          // this.equipForm.role = this.detailData.nickname;
                                        }
                                      }
                                    }).catch((err) => {
                                      console.log(err);
                                    });
                                }
                              }
                            }).catch((err) => {
                              console.log(err);
                            });
                        }
                      }
                    }).catch((err) => {
                      console.log(err);
                    });
                } else {
                  // console.log(4)
                  this.regionList();
                }
                break;
              default:
                //获取装备信息
                this.detailObj = res.data.goods_menu;
                this.equipForm.server_name = res.data.goods_detal.server_name;
                this.equipForm.server_id = res.data.goods_detal.server_id;
            }
            this.loading = false;
          }
        }).catch((err) => {
          this.isLoading = false;
          //console.log(err);
        });
    },
    //装备等详情跳转
    detailLink(item) {
      //console.log(item);
      let params = {
        goods_id: this.detailData.goods_id,
        product_id: this.detailData.goods_id,
        channel_id: this.params.channel_id,
        is_product: 1,
        api: item.api,
        name: item.name,
        key: item.key,
      };
      // 已上架
      // switch(item.key) {
      //     case 'property':
      //         //状态属性
      //         params = {
      //             goods_id : this.detailData.goods_id,
      //             is_product : 1,
      //             api: item.api,
      //             name: item.name
      //         };
      //         break;
      //     case 'role':
      //         //角色提升
      //         params = {
      //             product_id : this.detailData.goods_id,
      //             is_product : 1,
      //             channel_id:this.params.channel_id,
      //             api: item.api,
      //             name: item.name
      //         };
      //         break;
      //     case 'figure':
      //         //龙纹
      //         params = {
      //             product_id : this.detailData.goods_id,
      //             is_product : 1,
      //             api: item.api,
      //             name: item.name
      //         };
      //         break;
      //     case 'spiritpet':
      //         //灵兽
      //         params = {
      //             channel_id:this.params.channel_id,
      //             product_id : this.detailData.goods_id,
      //             is_product : 1,
      //             api: item.api,
      //             name: item.name
      //         };
      //         break;
      //     case 'modelclothes':
      //         //穿戴装备/时装
      //         params.type = 1;
      //         break;
      //     case 'knapsack':
      //         //背包
      //         params.type = 2;
      //         break;
      //     case 'warehouse':
      //         //仓库
      //         params.type = 3;
      //         break;
      //     case 'activityWareHouse':
      //         //活动仓库
      //         params.type = 4;
      //         break;
      //     case 'treasureWareHouse':
      //         //秘宝仓库
      //         params.type = 5;
      //         break;
      // }
      if (item.key == "spiritpet") {
        //灵兽
        this.$router.push({
          name: "spiritpet",
          query: params,
        });
      } else {
        this.$router.push({
          name: "property",
          query: params,
        });
      }
    },
    //判断是否已安全设置
    isSettig() {
      this.$api.account.isPaymentCode({ type: 1 }).then((res) => {
        if (res.code == 200) {
          if (res.data.length == 0) {
            this.isSet = false;
          } else {
            this.isSet = true;
          }
        }
      });
    },
    //立即购买
    payClick() {
      if (this.goods_type == 1) {
        // this.$router.push({
        //     name: 'paymentPage',
        //     query: {
        //         goods_id: this.detailData.goods_id,
        //         channel_id: this.params.channel_id
        //     }
        // });
        this.payshow = true;
      } else {
        this.equipShow = true;
        //this.state = 1;
        if (this.goods_type == 3) {
          let dataform = {
            channelId: this.params.channel_id,
            server_id: this.equipForm.server_id,
          };
          this.roleList(dataform);
        }
      }
    },
    //弹窗确认
    equipConfirm() {
      if (this.goods_type == 2) {
        if (
          this.currencyForm.region &&
          this.currencyForm.server &&
          this.currencyForm.role
        ) {
          // this.$router.push({
          //     name: 'paymentPage',
          //     query: {
          //         goods_id: this.detailData.goods_id,
          //         to_server: this.currencyForm.server_id,
          //         chrname: this.currencyForm.role,
          //         channel_id: this.params.channel_id
          //     }
          // });
          this.payshow = true;
        } else {
          return false;
        }
      } else {
        if (this.equipForm.server_name && this.equipForm.role) {
          // this.$router.push({
          //     name: 'paymentPage',
          //     query: {
          //         goods_id: this.detailData.goods_id,
          //         to_server: this.equipForm.server_id,
          //         chrname: this.equipForm.role,
          //         channel_id: this.params.channel_id
          //     }
          // });
          this.payshow = true;
        } else {
          return false;
        }
      }
    },
    //选择框确认
    onConfirm(value) {
      console.log(value,this.equipForm.rolePicker,this.currencyForm.gamePicker,this.currencyForm.serverPicker)
      if (this.equipForm.rolePicker) {
        this.currencyForm.server = value.server_name;
        this.currencyForm.server_id = value.server_id;
        this.equipForm.role = value.chrname;
        this.currencyForm.role = value.chrname;
        this.equipForm.rolePicker = false;
      } else if (this.currencyForm.gamePicker) {
        this.currencyForm.region = value.regione_name;
        this.currencyForm.regione_id = value.regione_id;
        this.currencyForm.server = "";
        this.currencyForm.servers = [];
        this.currencyForm.role = "";
        this.currencyForm.roles = [];
        this.currencyForm.gamePicker = false;
        this.state = 2;
        // console.log(this.params)
        this.serverList(value.regione_id);
      } else if (this.currencyForm.serverPicker) {
        this.currencyForm.server = value.server_name;
        this.currencyForm.server_id = value.server_id;
        this.currencyForm.role = "";
        this.currencyForm.roles = [];
        this.currencyForm.serverPicker = false;
        this.state = 2;
        this.roleList(this.currencyForm);
      } else {
        this.currencyForm.role = value.chrname;
        this.currencyForm.seedname = value.seedname;
        this.currencyForm.rolePicker = false;
      }
    },
    //根据渠道获取大区列表
    regionList() {
      this.$api.shopping.regionList({
          channel_id: this.params.channel_id,
        }).then((res) => {
          this.isLoading = false;
          if (res.code == 200) {
            this.currencyForm.regions = res.data;
            //首次点开默认值
            // console.log(this.state)
            if (this.state == 1) {
              // this.currencyForm.region = this.detailData.regione_name;
              // this.currencyForm.regione_id = this.detailData.regione_id;
              this.currencyForm.channelId = this.params.channel_id;
              if (this.currencyForm.channelId) {
                this.serverList();
              }
            }
          }
        }).catch((err) => {
          console.log(err);
        });
    },
    //根据渠道获取区服列表
    serverList(regione_id) {
      this.$api.helper.getServerList({
          channel_id: this.params.channel_id,
          regione_id:regione_id
        }).then((res) => {
          console.log(111)
          if (res.code == 200) {
            for (var key in res.data) {
              this.currencyForm.servers.push({
                server_name: res.data[key].server_name,
                server_id: res.data[key].server_id,
              });
            }
            //首次点开默认值
            if (this.state == 1) {
              for (var key in res.data) {
                if (this.detailData.server_id == res.data[key].server_id) {
                  // this.currencyForm.server = res.data[key].server_name
                }
              }
              // console.log(this.detailData.server_name,this.detailData.server_id)
              // this.currencyForm.server = this.detailData.server_name;
              this.currencyForm.server_id = this.detailData.server_id;
              this.roleList(this.currencyForm);
              // console.log(222,this.currencyForm)
            }
          }
        }).catch((err) => {
          console.log(err);
        });
    },
    //根据区服获取角色列表
    roleList(form) {
      // console.log(111,from)
      this.$api.helper.getPlayerList({
          channel_id: form.channelId,
          server_id: form.server_id,
          type: 1,
        }).then((res) => {
          if (res.code == 200) {
            if (this.goods_type == 2) {
              this.currencyForm.roles = res.data;
              //首次点开默认值
              // if (this.state == 1) {
              //     this.currencyForm.role = this.detailData.nickname;
              // }
            } else {
              this.equipForm.roles = res.data;
              // this.equipForm.role = this.detailData.nickname;
            }
          }
        }).catch((err) => {
          console.log(err);
        });
    },
    //选择框选择顺序提示
    changeClick(key) {
      //this.isToken();
      if (!localStorage.getItem("token")) {
        return false;
      }
      if (key == 1) {
        if (this.currencyForm.region) {
          this.currencyForm.serverPicker = true;
        } else {
          this.$toast({
            message: "请先选择大区，再选区服",
            duration: 1500,
          });
        }
      } else if (key == 2) {
        if (this.currencyForm.server) {
          this.currencyForm.rolePicker = true;
        } else {
          this.$toast({
            message: "请先选择区服，再选角色",
            duration: 1500,
          });
        }
      } else if (key == 3) {
        this.currencyForm.gamePicker = true;
      }
    },
  },
  beforeDestroy() {
    this.debounce = null; //清除闭包
    // console.log('销毁了')
  },
};
</script>
<style scoped>
.character_details {
  background: #ececec;
  height: 100%;
}
.character_details .index_list {
  display: flex;
  padding: 1.1875rem 1rem;
  border-bottom: 1px solid #bbbbbb;
  background: #fff;
  align-items: start;
}
.character_details .index_list .index_list_c {
  width: 57%;
  text-align: left;
  padding-left: 0.40625rem;
}
.character_details .index_list .index_list_c .index_list_c_t {
  font: 0.875rem/1.125rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  color: #313131;
  padding-bottom: 0.21875rem;
  margin-bottom: 0;
}
.character_details .index_list .index_list_c .index_list_c_c {
  font: 0.75rem/1.15625rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  color: #707070;
  padding-bottom: 0.21875rem;
}
.character_details .index_list .index_list_c .index_list_c_b {
  font: 0.875rem/1.0625rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  color: #ff4e00;
}
.character_details .index_list .profilePhoto {
  width: 3.75rem;
  height: 3.75rem;
  margin-right: 10px;
}
.character_details .index_list .authentication_img {
  width: 5.125rem;
  height: 1.65625rem;
}
.character_details .hint {
  height: 3.0625rem;
  border-bottom: 1px solid #dedede;
  background: #ececec;
  position: relative;
}
.character_details .hint div {
  padding: 0 1.40625rem;
  height: 2.5rem;
  background: #ff7456;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
  text-align: left;
}
.character_details .hint div svg {
  vertical-align: middle;
  width: 1.21875rem;
  height: auto;
}
.character_details .hint div span {
  display: inline-block;
  font: 0.75rem/2.5rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  color: #fff;
  vertical-align: middle;
}
.character_details .the_order_details {
  background: #fff;
  height: 4.84375rem;
  /* border-top: 1px solid #dedede; */
  border-bottom: 1px solid #dedede;
  padding: 0.4375rem 1.3125rem 0.09375rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.28125rem;
}
.character_details .the_order_details li {
  width: 50%;
  text-align: left;
  font: 0.6875rem/1.4375rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
}
/* .character_details .the_order_details li:last-child{
    width: 100%;
} */
.character_details .the_order_details li label {
  color: #313131;
}
.character_details .the_order_details li span {
  color: #707070;
}
.orange {
  color: #ff4e00 !important;
}
.van-cell {
  text-align: left;
}
.character_details .price_tag {
  width: 100%;
  height: 3.125rem;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: right;
  padding: 0.5rem 16px 0.4rem;
}
.price_tag .purchase_btn {
  width: 5.625rem;
  height: 2.125rem;
  vertical-align: middle;
  margin: 0;
  float: right;
}
.price_tag .price_item {
  float: left;
  margin-right: 0.6rem;
  text-align: center;
}
.price_tag .price_item:first-child {
  width: 2.25rem;
}
.price_tag .price_item a {
  display: block;
}
.price_tag .price_item img {
  width: 1.2rem;
}
.price_tag .price_item span {
  color: #808080;
}
.pop_wrap .pop_w p:first-child {
  text-align: center;
  margin-bottom: 0.5rem;
}
.pop_wrap .pop_w .on_price {
  margin: 0.5rem 0;
}
.lightgray {
  color: #b7b7b7;
}
.price_tag .orange {
  font: 14px/34px Open Sans, -apple-system, BlinkMacSystemFont, Helvetica Neue,
    Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB,
    Microsoft Yahei, sans-serif;
  vertical-align: middle;
  float: left;
  font-weight: bold;
}
.price_tag .price_tag_word {
  font: 14px/34px Open Sans, -apple-system, BlinkMacSystemFont, Helvetica Neue,
    Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB,
    Microsoft Yahei, sans-serif;
  color: #323233;
  vertical-align: middle;
  float: left;
}

.equip_w {
  padding: 4% 2% 3%;
}
.equip_w p {
  font-size: 13px;
}
.choosePay .close {
  height: 0.9792rem;
  position: relative;
  margin-top: 0.7083rem;
}
.choosePay .close i {
  position: absolute;
  background: url("../../assets/images/close.png") no-repeat;
  background-size: 100% 100%;
  width: 1rem;
  height: 0.9792rem;
  top: 0;
  right: 0.75rem;
}
.choosePay .title {
  font-size: 1.0208rem;
  color: #313131;
  padding-bottom: 1.0208rem;
  border-bottom: 0.0417rem solid #b3b3b3;
}
.choosePay_list {
  padding: 1.7708rem 0 0.4167rem;
}
.choosePay_list li {
  display: flex;
  justify-content: space-between;
  width: 88%;
  margin: 0 auto 1.4167rem;
  height: 4.1042rem;
  background: #efefef;
  border-radius: 0.5rem;
  align-items: center;
  border: 0.0417rem solid #d8d8d8;
}
.choosePay_list li img {
  width: 1.9375rem;
  height: 1.9375rem;
  margin-left: 1.1042rem;
}
.choosePay_list li > div {
  width: 86%;
  text-align: center;
  font-size: 1.0833rem;
}
.blue {
  color: #19a0e5;
}
.choosePay_list .yue {
  font-size: 0.8542rem;
  color: #808080;
}

.password_input .close {
  text-align: right;
  font: 0.875rem/1.3125rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  padding-top: 0.3125rem;
}
.password_input .close i {
  margin-right: 0.3375rem;
}
.password_border {
  border-radius: 0.84375rem;
  background: #efefef;
}
.password_input .password_text {
  font: 0.9375rem /1.875rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  color: #313131;
  border-bottom: 1px solid #b3b3b3;
}
.password_input .password_sum {
  font: 900 1.34375rem/1.84375rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  margin-top: 0.9375rem;
}
.password_input .password_deduction {
  font: 0.71875rem/1.21875rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  padding-bottom: 0.71875rem;
  border-bottom: 1px dashed #b3b3b3;
  margin-bottom: 0.96875rem;
}
.van-password-input {
  padding-top: 3.0625rem;
}
.affirm_pay {
  width: 12.5rem;
  margin: 1.8125rem auto 1.9167rem;
}
.van-password-input__security li {
  background: #f0f0f0;
  margin-right: 0.1875rem;
}
.password_input .password_forget {
  font: 0.98125rem/2.71875rem Open Sans, -apple-system, BlinkMacSystemFont,
    Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui,
    Hiragino Sans GB, Microsoft Yahei, sans-serif;
  color: #227eeb;
  text-align: right;
  padding-right: 1rem;
}
.van-number-keyboard {
  z-index: 200004;
}
/* .van-hairline--surround::after{
    border: 0;
} */
</style>
<style>
.choosePay .van-hairline--surround::after {
  border: 0;
}
</style>
<style scoped>
@import "../../assets/css/common.css";
</style>
